import React from 'react';
import './Privacy.css'

export class Privacy extends React.Component {
    render() {
      return (
        <div>
            <div className='privacy-policy'>
            <h3>Privacy Policy</h3>
            Currently we only collect questions that you submit via the app along with your device token (so we can hopefully notify you once we have an answer).
            </div>
        </div>
      )
    }
}