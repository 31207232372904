import React from 'react';
import './Roadmap.css';

const Roadmap = () => {
  return (
    <div className="roadmap-container">
      <h2 className="roadmap-title">Our Roadmap</h2>
      <div className="roadmap-timeline">
        <div className="roadmap-timeline-item">
          <div className="roadmap-timeline-icon">1</div>
          <div className="roadmap-timeline-content">
            <h3 className="roadmap-timeline-content-title">Introduce & Fund</h3>
            <p className="roadmap-timeline-content-description">
              Host small events to introduce company ideals and gather funding
            </p>
          </div>
        </div>
        <div className="roadmap-timeline-line"></div>
        <div className="roadmap-timeline-item">
          <div className="roadmap-timeline-icon">2</div>
          <div className="roadmap-timeline-content">
            <h3 className="roadmap-timeline-content-title">Host Festival</h3>
            <p className="roadmap-timeline-content-description">
              After sufficient interest and funding, host a large festival
            </p>
          </div>
        </div>
        <div className="roadmap-timeline-line"></div>
        <div className="roadmap-timeline-item">
          <div className="roadmap-timeline-icon">3</div>
          <div className="roadmap-timeline-content">
            <h3 className="roadmap-timeline-content-title">Build Campus</h3>
            <p className="roadmap-timeline-content-description">
              Use festival funding to build the first "healing campus"
            </p>
          </div>
        </div>
        <div className="roadmap-timeline-line"></div>
        <div className="roadmap-timeline-item">
          <div className="roadmap-timeline-icon">4</div>
          <div className="roadmap-timeline-content">
            <h3 className="roadmap-timeline-content-title">Fine Tune & Scale</h3>
            <p className="roadmap-timeline-content-description">
              Fine-tune festival/campus building process and scale to multiple locations
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
