import React, { Component } from 'react';
import './Home.css'
// import Logo from './icon.svg';
// import AppStoreLogo from './app-store.svg'
import Autocomplete from "react-google-autocomplete";

export class Home extends React.Component {
    render() {
      return (
        <div>
            <div className='nav'>
                <h2>R A M A A</h2>

                <p className='right-nav'>
                  <a className='nav-contact' href="mailto:ian@ramaa.org">contact</a>
                  <a className='nav-donate' href="https://www.paypal.com/donate/?hosted_button_id=6VTFUJBW3H6AJ">donate</a>
                  <a className='nav-join' href="https://docs.google.com/forms/d/e/1FAIpQLSe57E6nuuMPVctO-PGcr8t4mPhI7Kc_p8UHIuG6yGkGF2tTQg/viewform?usp=sf_link"> join us</a>
                </p>
            </div>
            <div className='body'>
              <div className='top'>
                <div className='mission-statement'>
                  Celebrate. Love. Evolve.
                </div>
                <div className='mission'>
                  Ramaa's mission is to create a world where we come together to celebrate life, love one another, and evolve through holistic healing practices in sustainable, nature-integrated communities.
                </div>
                <div className='video'>
                  <iframe src='https://www.youtube.com/embed/pcZtMmMyYIo'
                    frameborder='0'
                    allow='autoplay; encrypted-media'
                    allowfullscreen
                    title='video'
                  />
                </div>
              </div>
              <div className='middle'>
                <div className='festival'>
                  <div className='image'>
                    <img className="image" src={require('./festival.png')} />
                  </div>
                  <div className='header'>
                    <b>festivals</b> - a celebration of life, love and inner evolution
                  </div>
                  <div className='text'>
                    These festivals are not your ordinary gatherings, they are powerful tools to jumpstart the building and funding process of healing villages and campuses. These themed festivals will not only gather interest and volunteers, but also generate ticket sales to make everything feasible. Each festival will serve to foster a sense of community and belonging among participants as well as inspire an imaginative vision for what life could be like.
                  </div>
                </div>
                <div className='campus'>
                  <div className='image'>
                    <img className="image" src={require('./campus.png')} />
                  </div>
                  <div className='header'>
                    <b>healing campuses</b>  -  a sanctuary for self-discovery
                  </div>
                  <div className='text'>
                    Healing Campuses are not just buildings, they are sanctuaries for self-discovery. Imagine a place where nature and beauty are seamlessly integrated with healing and growth. A place where you can come to be supported in whatever spiritual or holistic practice you are doing, without any forced dogma. Open-mindedness, kindness, and love will be the fabric that ties together these campuses. Free retreats, events and work exchange programs are a must. Luxury stay options and retreats as well as annual community festivals and donations could serve as a base to sustain individual campuses.  
                  </div>
                </div>
              </div>
              <div className='bottom'>
                <div className='connect'>
                  <form action="https://formsubmit.co/47fbdbd564c11e607e646cffcc206e89" method="POST">
                    <input type="hidden" name="_next" value="https://www.ramaa.org?message=thank-you"/>
                    <input type="hidden" name="_captcha" value="false"/>
                    <div className='connect-header'>
                      stay in the loop!
                    </div>
                    <div className='input'>
                    <input placeholder='name' type="text" name="name" required/>
                    </div>
                    <div className='input'>
                      <input placeholder='email' type="email" name="email" required/>
                    </div>
                    <div className='city-text'>
                      Get updates about festivals and events in your area.
                    </div>
                    <div className='city-input'>
                      <Autocomplete
                      name="city"
                      className='autocomplete'
                      apiKey={"AIzaSyC1k4wBB-s1DucI2ex6dK8BhkAQx8-sTlA"}
                      onPlaceSelected={(place) => {
                        console.log(place);
                      }}
                      placeholder='city (optional)'
                    />;
                    </div>
                    <div className='updates-text'>
                      By pressing 'update me' you agree to being kept in the loop.
                    </div>
                    <div className='button'>
                      <button type="submit" className='updates' onclick="updateme()">
                        update me
                      </button>
                    </div>
                  </form>
                </div>
                <div className='bottom-nav'>
                  <a className='link-left' href="https://www.paypal.com/donate/?hosted_button_id=6VTFUJBW3H6AJ">donate</a>
                  <a className='link-right' href="https://docs.google.com/forms/d/e/1FAIpQLSe57E6nuuMPVctO-PGcr8t4mPhI7Kc_p8UHIuG6yGkGF2tTQg/viewform?usp=sf_link">join us</a>
                </div>
              </div>
            </div>
        </div>
      )
    }
  }