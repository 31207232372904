import logo from './logo.svg';
import './App.css';
// import { Landing } from './Landing.js'
import { Home } from './Home.js'
import { Privacy } from './Privacy.js'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Roadmap from './Roadmap';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/privacypolicy" element={<Privacy/>}/>
          <Route path="/roadmap" element={<Roadmap/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
